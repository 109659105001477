import FormComponent from "./form.component.vue";
import {couponService} from "../../../../services/facility/coupon/coupon.service";
import PaginateComponent from "../../../components/paginate.component.vue";
import {alertService} from "../../../../services/alert/alert.service";

export default {
    components: {FormComponent,PaginateComponent},
    data() {
        return {
            data: {
                data: []
            },
            filter: {
                paginate: 1,
                per_page: 10,
                page: 1
            },

        }
    },
    async mounted() {
       await this.list(this.filter)

    },
    methods: {

        async list(query) {

            await couponService.list(query).then((success) => {
                this.data = success.data;
                console.log(  this.data);
            });

        },
        async search$(data) {

            this.filter = {...this.filter, ...data};
            await this.list(this.filter);
        },
        async edit(data){
            this.$refs.form.init_data(data);
        },
        async copy(data){
            delete data.id;
            delete data.identification_number;
            data.offer_status = 1;
            this.$refs.form.init_data(data);
        },

        async delete$(id) {
            var callback = async () => {
                await couponService.delete(id)
                await this.list({...this.filter, page: 1});
            }
            alertService.confirmation(callback, this.$t("facility.global.are-you-sure-delete"), this.$t("facility.global.cannot-reverted"));
        },

    }

}