import { alertService } from "../../../../services/alert/alert.service";
import { couponService } from "../../../../services/facility/coupon/coupon.service";
import { reservationFeeService } from "../../../../services/facility/reservation-fee/reservation-fee.service";

export default {
  data() {
    return {
      data: {
        discount_amount: 0,
        discount_rate: 1,
        offer_status: 1,
      },
      default_data: {
        discount_type: 1,
        discount_amount: 0,
        discount_rate: 1,
        offer_status: 1,
      },
      errors: {},
    };
  },
  async mounted() {},
  watch: {},
  methods: {
    async submit() {
      var callback = async () => (this.data.id ? this.update() : this.store());

      alertService.confirmation(callback, this.$t("facility.global.confirmation"), this.$t("facility.global.are-you-sure"));
    },
    async store() {
      await couponService
        .store(this.data)
        .then((result) => {
          alertService.success(this.$t("facility.global.success"), this.$t("facility.global.success"));
          this.data = { ...this.default_data };
          this.$emit("event", {});

          this.errors = {};
          this.$bvModal.hide("coupon-form");
        })
        .catch((errors) => {
          this.errors = errors.errors;
        });
    },
    async update() {
      await couponService
        .update(this.data.id, this.data)
        .then((result) => {
          alertService.success(this.$t("facility.global.success"), this.$t("facility.global.updated"));
          this.data = { ...this.default_data };
          this.$emit("event", {});

          this.errors = {};
          this.$bvModal.hide("coupon-form");
        })
        .catch((errors) => {
          this.errors = errors.errors;
        });
    },
    async init_data(data) {
      let d = { ...data };

      this.data = d;

      this.$bvModal.show("coupon-form");
    },
    get_discount_amount(mode) {
      let amount = 0;
      if (this.data.discount_rate == mode) {
        amount = this.data.discount_amount;
      }

      return amount;
    },
    set_discount_amount($event) {
      this.data.discount_amount = $event.target.value;

      console.log(this.data.discount_amount);
    },
  },
};
